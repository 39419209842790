import { Box } from '@mui/material';
import MapleIcon, { MapleIconProps } from 'Components/ds/MapleIcon';

interface MapleIconBadgeProps extends MapleIconProps {
  badge?: React.ReactNode;
}

const badgeCircleStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(23%, -28%)',
  width: '20px',
  height: '20px',
};

const MapleIconBadge = ({ icon, badge, width, height, ...rest }: MapleIconBadgeProps) => {
  return (
    <Box position='relative' width={width} height={height}>
      <MapleIcon icon={icon} width={width} height={height} {...rest} />
      {badge && <Box sx={{ ...badgeCircleStyles }}>{badge}</Box>}
    </Box>
  );
};

export default MapleIconBadge;
