import { CollateralAsset } from 'Graphql/schema';

export const FIAT_DECIMALS = 6;

// token decimals
export const MPL_DECIMALS = 18;
export const USDC_DECIMALS = 6;
export const USDT_DECIMALS = 6;
export const WETH_DECIMALS = 18;
export const WBTC_DECIMALS = 8;
export const GBTC_DECIMALS = 4;
export const SOL_DECIMALS = 18;
export const XMPL_DECIMALS = 18;
export const AAVE_DECIMALS = 18;
export const LINK_DECIMALS = 18;
export const ADA_DECIMALS = 6;
export const AVAX_DECIMALS = 18;
export const BNB_DECIMALS = 18;
export const FIL_DECIMALS = 18;
export const XRP_DECIMALS = 6;
export const SYRUP_DECIMALS = 18;
export const STABLECOIN_DECIMALS = 6;
export const STABLECOIN_FACTOR = 1e6;
export const WEI_DECIMALS = 18;

// rate decimals
export const APY_RATE_DEC = 18;
export const APY_DECIMALS = 30;
export const FEE_DECIMALS = 6; // v2 fee rates
export const INTEREST_RATE_DECIMALS = 18;
export const UTILIZATION_RATE_DECIMALS = 30;
export const RATE_DECIMALS = 6;
export const ST_SYRUP_ISSUANCE_RATE_PRECISION = 18;

// UI decimals
export const DEFAULT_COINS_UI_DECIMALS = 4;
export const MPL_EXPANDED_UI_DECIMALS = 6;
export const PERCENTAGE_UI_DECIMALS = 1;
export const STABLE_COINS_UI_DECIMALS = 2;

export const STABLE_COINS = ['USDC', 'DAI'];

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const MPL_SYRUP_CONVERSION_RATE = 100;

export const COLLATERAL_COLOR: Record<CollateralAsset | 'default', string> = {
  ADA: '#0033AD',
  AKT: '#FF414C',
  AVAX: '#E84142',
  aUSDC: '#2775CA',
  aUSDT: '#22A079',
  BNB: '#F0B90B',
  BTC: '#F7931A',
  ETH: '#CACAD9',
  FIL: '#0090FF',
  FTM: '#1969FF',
  GBTC: '#3C3259',
  INJ: '#00F2FE',
  LBTC: '#C4FDF2',
  LTC: '#BEBEBE',
  POL: '#7342DC',
  PT_sUSDE: '#18E3C2',
  S: '#141416',
  SOL: '#9647FD',
  USDC: '#2775CA',
  USTB: '#C0A066',
  WSTETH: '#35B4FF',
  XRP: '#23292F',
  OrcaLP_PYUSDC: '#0071F3',
  sDAI: '#78C024',
  sUSDS: '#0EC59F',
  tETH: '#007FED',
  default: '#A88FFF',
};
