import { useState, useMemo, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';

// Components
import ClaimBanner from 'Components/ClaimBanner';
import CommitBanner from 'Components/CommitBanner';
import UniswapBanner from 'Components/UniswapBanner';
import MapleCarousel from 'Components/ds/MapleCarousel';
import MapleDialog from 'Components/ds/MapleDialog';

// Constants
import { BANNERS } from 'Components/DesktopBannersCarousel';

// Context
import { ClientContext } from 'Context/Client';
import { ClaimContext } from 'Context/Claim';
import { PortfolioContext } from 'Context/Portfolio';

// Hooks
import useIsGnosisSafe from 'Hooks/useIsGnosisSafe';

const MobileBannersCarousel = () => {
  const { connected } = useContext(ClientContext);
  const { claimState, currentClaimPeriod, loading } = useContext(ClaimContext);
  const { userState } = useContext(PortfolioContext);
  const gnosisConnectionType = useIsGnosisSafe();

  const [open, setOpen] = useState<null | boolean>(null);
  const [showClaimBanner, setShowClaimBanner] = useState(false);
  const [showCommitBanner, setShowCommitBanner] = useState(false);
  const [showUniswapBanner, setShowUniswapBanner] = useState(
    localStorage.getItem(BANNERS.UNISWAP.storageKey) !== 'closed',
  );

  useEffect(() => {
    if (!connected) return setShowClaimBanner(false);

    if (currentClaimPeriod === localStorage.getItem(BANNERS.CLAIM.storageKey)) return setShowClaimBanner(false);

    return setShowClaimBanner(claimState === 'claim-available');
  }, [loading, connected, claimState, currentClaimPeriod]);

  useEffect(() => {
    if (!connected || localStorage.getItem(BANNERS.COMMIT.storageKey) || gnosisConnectionType !== 'none')
      return setShowCommitBanner(false);

    return setShowCommitBanner(userState !== 'no-balance');
  }, [connected, userState, gnosisConnectionType]);

  const banners = useMemo(() => {
    if (open === false) return [];

    const availableBanners: { [key: string]: React.ReactNode } = {};

    if (showClaimBanner) {
      availableBanners['CLAIM'] = <ClaimBanner onClose={() => setShowClaimBanner(false)} />;
    }

    if (showCommitBanner) {
      availableBanners['COMMIT'] = <CommitBanner userState={userState} onClose={() => setShowCommitBanner(false)} />;
    }

    if (showUniswapBanner) {
      availableBanners['UNISWAP'] = <UniswapBanner onClose={() => setShowUniswapBanner(false)} />;
    }

    setOpen(true);

    const sortedBanners = Object.keys(availableBanners).sort((a, b) => BANNERS[a].priority - BANNERS[b].priority);

    return sortedBanners.map(key => availableBanners[key]);
  }, [open, showClaimBanner, showCommitBanner, showUniswapBanner, userState]);

  return (
    <MapleDialog open={banners.length > 0} handleClose={() => setOpen(false)} padding={0}>
      <Box sx={{ pt: 1 }}>
        <MapleCarousel
          height={345}
          name='sidebar-banners'
          itemsSpacing={0}
          margins={1}
          navigation='top'
          items={banners}
        />
      </Box>
    </MapleDialog>
  );
};

export default MobileBannersCarousel;
