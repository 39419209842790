import { Box, Stack, styled, SxProps } from '@mui/material';

// Components
import MapleTooltip, { MapleTooltipProps } from 'Components/ds/MapleTooltip';
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon, { MapleIconProps } from 'Components/ds/MapleIcon';

export type MaplePillProps = {
  label: string;
  color?: string;

  variant: 'outlined' | 'contained';
  disabled?: boolean;

  sx?: SxProps;

  tooltip?: MapleTooltipProps;
  icon?: MapleIconProps['icon'];
};

const StyledPill = styled(Box)<{ variant; disabled: boolean }>(({ variant, disabled, theme }) => ({
  ...theme.typography.label,
  borderRadius: theme.shape.xl,
  width: 'fit-content',

  ...(variant === 'outlined' && {
    ...theme.typography.label.small,
    border: `1px solid ${theme.palette.stroke.sub}`,
    backgroundColor: 'transparent',
    color: theme.palette.text.strong,
    padding: theme.spacing(1),
    boxShadow: theme.syrupShadows.md,
  }),
  ...(variant === 'contained' && {
    ...theme.typography.label.xSmall,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    padding: theme.spacing(0.5, 1),
  }),
  ...(disabled && {
    backgroundColor: theme.palette.background.soft,
    color: theme.palette.text.soft,
  }),
}));

const MaplePill = ({ label, variant, tooltip, icon, sx, disabled = false, color }: MaplePillProps) => {
  return (
    <StyledPill variant={variant} disabled={disabled} sx={sx}>
      <Stack direction='row' alignItems='center' justifyContent='center' spacing={0.5}>
        {icon && <MapleIcon icon={icon} width={16} height={16} color={color} />}
        <MapleTypography variant='label' size='xSmall'>
          {label}
        </MapleTypography>
        {tooltip && <MapleTooltip {...tooltip} />}
      </Stack>
    </StyledPill>
  );
};

export default MaplePill;
