// Creates a cached version of an async function to avoid redundant calls with the same inputs
export function cache<Args extends unknown[], Result>(
  fn: (...args: Args) => Promise<Result>,
): (...args: Args) => Promise<Result> {
  const cache = new Map<string, Result>();

  return async (...args: Args): Promise<Result> => {
    // Create a cache key from serialized arguments
    const key = JSON.stringify(args.map(arg => (typeof arg === 'bigint' ? arg.toString() : arg)));

    if (cache.has(key)) {
      return cache.get(key)!;
    }

    const result = await fn(...args);
    cache.set(key, result);
    return result;
  };
}
