import { createPublicClient, http } from 'viem';
import { CHAIN } from 'Constants/network';

export const client = createPublicClient({
  chain: CHAIN,
  transport: http(CHAIN.rpcUrls.default.http[0], {
    timeout: 15000,
    retryCount: 3,
    retryDelay: 1000,
  }),
});
