import { getContract, parseUnits } from 'viem';
import { client } from 'Utils/rpc';

// Abis
import xmplAbi from 'Contracts/abis/xMPL';

// Constants
import { CHAIN, SECONDS_IN_YEAR, TEN, ZERO } from 'Constants';
import { ADDRESSES } from 'Contracts/addresses';
import {
  APY_DECIMALS,
  ST_SYRUP_ISSUANCE_RATE_PRECISION,
  STABLE_COINS_UI_DECIMALS,
  SYRUP_DECIMALS,
  UTILIZATION_RATE_DECIMALS,
  XMPL_DECIMALS,
} from 'Constants/tokens';

// Types
import { PublicActionsWithClientWallet } from 'Context/Client/Client';

// Utils
import {
  buildPercentageValueInterfaceFromBigNumber,
  buildValueInterfaceFromBigNumberValues,
  ValueInterface,
  defaultValueInterface,
} from 'Utils/valueInterface';
import { getNowTimestamp } from 'Utils/timeAndDates';

// RPC Data
/// ------------------------
export const getStakingRPCData = async (): Promise<Record<'totalAssets', bigint>> => {
  try {
    const stSyrupContract = getContract({ address: ADDRESSES.stSyrup, abi: xmplAbi, client });
    const totalAssets = await stSyrupContract.read.totalAssets();

    return {
      totalAssets,
    };
  } catch (error) {
    console.error('Error in getStakingRPCData:', error);
    return { totalAssets: ZERO };
  }
};

// Conversions
// -----
export const getStSyrupInSyrup = async (
  client: PublicActionsWithClientWallet,
  shares: bigint,
): Promise<ValueInterface> => {
  const chain = await client.getChainId();
  if (chain != CHAIN.id) return { ...defaultValueInterface };

  try {
    const stSyrupContract = getContract({ address: ADDRESSES.stSyrup, abi: xmplAbi, client });
    const stSyrupShares = await stSyrupContract.read.convertToAssets([shares]);

    const valueInterface = buildValueInterfaceFromBigNumberValues(
      stSyrupShares,
      XMPL_DECIMALS,
      STABLE_COINS_UI_DECIMALS,
    );

    return valueInterface;
  } catch (error) {
    console.error('Error in getStSyrupInSyrup:', error);
    throw error;
  }
};

export const getSyrupInStSyrup = async (
  client: PublicActionsWithClientWallet,
  assets: bigint,
): Promise<ValueInterface> => {
  const chain = await client.getChainId();
  if (chain != CHAIN.id) return { ...defaultValueInterface };

  try {
    const stSyrupContract = getContract({ address: ADDRESSES.stSyrup, abi: xmplAbi, client });
    const stSyrupShares = await stSyrupContract.read.convertToShares([assets]);

    const valueInterface = buildValueInterfaceFromBigNumberValues(
      stSyrupShares,
      XMPL_DECIMALS,
      STABLE_COINS_UI_DECIMALS,
    );

    return valueInterface;
  } catch (error) {
    console.error('Error in getSyrupInStSyrup:', error);
    throw error;
  }
};

/// ------------------------
interface GetStakingApyInputs {
  totalAssets: bigint;
  issuanceRate: number;
  vestingPeriodFinish: number;
}

export const getStakingApy = ({
  issuanceRate,
  totalAssets,
  vestingPeriodFinish,
}: GetStakingApyInputs): ValueInterface => {
  let apy = ZERO;

  const currentTime = getNowTimestamp();
  const isPeriodFinished = vestingPeriodFinish <= currentTime;

  if (!isPeriodFinished && totalAssets > 0n) {
    const scaleFactor = TEN ** BigInt(APY_DECIMALS); // scale up to preserve precision
    const numerator = BigInt(issuanceRate) * BigInt(SECONDS_IN_YEAR) * scaleFactor;
    const denominator = totalAssets * BigInt(ST_SYRUP_ISSUANCE_RATE_PRECISION); // divide by 18 (vs. 1e30) a mistake in the smart contract config
    apy = numerator / denominator;
  }
  const apyFormatted = buildPercentageValueInterfaceFromBigNumber(apy, APY_DECIMALS);

  return {
    bigNumber: apy,
    parsed: apy.toString(),
    formatted: apyFormatted.formatted,
  };
};

interface GetStakingRewardsDistributedInputs {
  issuanceRate: number;
  vestingPeriodFinish: number;
  vestingPeriodStart: number;
  vestedAssetsPriorPeriods: number;
}

export const getStakingRewardsDistributed = ({
  issuanceRate,
  vestingPeriodFinish,
  vestingPeriodStart,
  vestedAssetsPriorPeriods,
}: GetStakingRewardsDistributedInputs): ValueInterface => {
  const currentTime = getNowTimestamp();
  const isPeriodFinished = vestingPeriodFinish <= currentTime;

  const elapsed = isPeriodFinished
    ? BigInt(vestingPeriodFinish - vestingPeriodStart)
    : BigInt(currentTime - vestingPeriodStart);

  const vestedAssetsPrior = BigInt(vestedAssetsPriorPeriods) || ZERO;
  const ST_SYRUP_ISSUANCE_RATE_PRECISION_DECIMALS_ADJUSTMENT = BigInt(ST_SYRUP_ISSUANCE_RATE_PRECISION);
  const vestedAssetsCurrent = (BigInt(issuanceRate) * elapsed) / ST_SYRUP_ISSUANCE_RATE_PRECISION_DECIMALS_ADJUSTMENT;

  const totalRewardsDistributed = vestedAssetsPrior + vestedAssetsCurrent;

  let value = ZERO;

  if (totalRewardsDistributed > 0n) {
    value = totalRewardsDistributed;
  }

  return buildValueInterfaceFromBigNumberValues(value, SYRUP_DECIMALS);
};

export const getStakedSupplyPercentage = async ({ totalAssets }: { totalAssets: bigint }): Promise<ValueInterface> => {
  try {
    const syrupResponse = await fetch(
      'https://api.coingecko.com/api/v3/coins/syrup?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false',
    );

    const syrupData = await syrupResponse.json();

    const syrupCirculatingSupply = parseUnits(syrupData.market_data.circulating_supply.toString(), SYRUP_DECIMALS);

    const scaleFactor = BigInt(10) ** BigInt(UTILIZATION_RATE_DECIMALS);
    const scaledTotalAssets = totalAssets * scaleFactor;

    return buildPercentageValueInterfaceFromBigNumber(
      scaledTotalAssets / syrupCirculatingSupply,
      UTILIZATION_RATE_DECIMALS,
    );
  } catch (e: unknown) {
    console.error(`Error calculateStakingPercent`, String(e));

    return { ...defaultValueInterface };
  }
};
