import { Address, getContract } from 'viem';
import poolAbi from 'Contracts/abis/PoolV2Pool';
import { SCALED_APY } from 'Constants/apy';
import { ZERO } from 'Constants/numbers';
import { PoolApyCachedFragment, PoolFragment, PoolWithdrawalFragment } from 'Graphql/schema';
import { cache } from 'Utils/cache';
import { isDust } from 'Utils/numbers';
import { client } from 'Utils/rpc';

export const convertToExitShares = async (assets: bigint, pool: PoolWithdrawalFragment): Promise<bigint> => {
  try {
    const poolContract = getContract({
      address: pool.id as Address,
      abi: poolAbi,
      client,
    });
    const exitShares = await poolContract.read.convertToExitShares([assets]);

    return exitShares;
  } catch (error) {
    console.error('Error in convertToExitShares:', error);

    return ZERO;
  }
};

export const convertToExitAssets = cache(async (shares: bigint, pool: PoolWithdrawalFragment): Promise<bigint> => {
  try {
    const poolContract = getContract({
      address: pool.id as Address,
      abi: poolAbi,
      client,
    });
    const exitAssets = await poolContract.read.convertToExitAssets([shares]);

    return exitAssets;
  } catch (error) {
    console.error('Error in convertToExitAssets:', error);

    return ZERO;
  }
});

export const getWithdrawalShares = async ({
  amount,
  pool,
  availableBalance,
  availableShares,
}: {
  amount: bigint;
  pool: PoolWithdrawalFragment;
  availableBalance: bigint;
  availableShares: bigint;
}): Promise<bigint> => {
  const isDustLeftover = isDust(availableBalance - amount);

  // make sure all shares are requested in case user inputs (almost) full balance
  let shares = availableShares;

  if (!isDustLeftover) {
    shares = await convertToExitShares(amount, pool);
  }

  return shares;
};

export const getTargetYieldBN = (targetYield: number | undefined | null): bigint => {
  const safeFactor = 10 ** 18;
  const targetYieldSafe = (targetYield || 0) * safeFactor;
  const targetYieldRate = targetYieldSafe
    ? (SCALED_APY * BigInt(targetYieldSafe)) / (BigInt(100) * BigInt(safeFactor))
    : ZERO;

  return targetYieldRate;
};

export const getSyrupApy = (syrupPool: PoolFragment & PoolApyCachedFragment): string => {
  if (!syrupPool) return '0';

  const { monthlyApy, poolMeta } = syrupPool;

  if (monthlyApy && monthlyApy !== '0') return monthlyApy; // preferably render 30-day APY

  const targetYieldBN = getTargetYieldBN(poolMeta?.targetYield);

  return targetYieldBN.toString();
};
