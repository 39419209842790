import {
  BasicTransactionFragment,
  CommitmentFragment,
  GetPortfolioDataQuery,
  PoolV2PositionFragment,
  PoolWithdrawalFragment,
  TxType,
  WithdrawalRequest,
} from 'Graphql/schema';

import { PositionData, PoolAssets, WithdrawalData, WithdrawalStates, StatsData } from 'Context/Portfolio/Portfolio';

// Constants
import { ZERO } from 'Constants/numbers';
import { USDC_DECIMALS, STABLE_COINS_UI_DECIMALS } from 'Constants/tokens';
import { defaultPositionData, defaultWithdrawalData } from 'Utils/defaultValues';

// Utils
import { i18nStringifiedNumberFormat } from 'Utils/numbers';
import { getCommitmentEnds, commitmentLengthMap } from 'Utils/points';
import { convertToExitAssets } from 'Utils/pool';
import { applyCustomDateFormat, CountdownResult, getCountdownFromTimestamp } from 'Utils/timeAndDates';
import {
  buildValueInterfaceFromParsedValues,
  buildValueInterfaceFromBigNumberValues,
  ZERO_USDC,
  ValueInterface,
} from 'Utils/valueInterface';
import { getTargetYieldAverage } from 'Utils/stats';

export const getFormattedPositionData = async (
  poolV2: PoolWithdrawalFragment,
  position?: PoolV2PositionFragment,
): Promise<PositionData> => {
  if (!position) return { ...defaultPositionData };

  const {
    commitments: commitmentData,
    availableBalance: userAvailableBalance,
    availableShares: uncommitedShares,
    lendingBalance,
    interestEarned: userInterest,
    lockedShares: lockedSharesParsed,
    dripsEarned: positionDripsEarned,
  } = position;

  const totalBalance = buildValueInterfaceFromParsedValues(
    lendingBalance || '0',
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );

  const interestEarned = buildValueInterfaceFromParsedValues(
    userInterest || '0',
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );

  const availableBalance = buildValueInterfaceFromBigNumberValues(
    BigInt(userAvailableBalance || '0'),
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );

  const userAvailableShares = BigInt(uncommitedShares || '0') - BigInt(lockedSharesParsed);

  const availableShares = buildValueInterfaceFromBigNumberValues(
    userAvailableShares,
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );

  const lockedShares = buildValueInterfaceFromParsedValues(lockedSharesParsed, USDC_DECIMALS, STABLE_COINS_UI_DECIMALS);

  const { commitments, firstCommitmentExpiry } = await getCommitments(poolV2, commitmentData || []);

  const dripsEarned = i18nStringifiedNumberFormat(positionDripsEarned?.toString() ?? '0', 0);

  const asset = poolV2.asset.symbol.toLowerCase() as PoolAssets;

  return {
    asset,
    totalBalance,
    interestEarned,
    availableBalance,
    availableShares,
    lockedShares,
    commitments,
    firstCommitmentExpiry,
    dripsEarned,
  };
};

export interface FormattedCommitment {
  id: string;
  amount: ValueInterface;
  exitAssets: ValueInterface;
  length: string;
  unlockDate: string;
  isExpired: boolean;
  unlockTimestamp: number;
  asset: PoolAssets;
}

export const getFormattedCommitments = async (
  commitments: CommitmentFragment[],
  pool: PoolWithdrawalFragment,
): Promise<FormattedCommitment[]> => {
  const now = Date.now();
  const filteredItems = commitments
    .filter(({ dripsPerDay, isWithdrawnEarly, shares, withdrawnShares }) => {
      const isRecommitted = withdrawnShares === shares;
      return dripsPerDay !== 1 && !isWithdrawnEarly && !isRecommitted;
    })
    .sort((a, b) => getCommitmentEnds(+a.date, a.days) - getCommitmentEnds(+b.date, b.days));

  const items = await Promise.all(
    filteredItems.map(async commitment => {
      const { amount, date: startDateTimestamp, days, shares } = commitment;

      const length = commitmentLengthMap[days];
      const unlockTimestamp = getCommitmentEnds(+startDateTimestamp, days);
      const unlockDate = applyCustomDateFormat(unlockTimestamp);
      const isExpired = unlockTimestamp < now;

      const amountFormatted = buildValueInterfaceFromParsedValues(amount, USDC_DECIMALS, STABLE_COINS_UI_DECIMALS);

      const exitAssetsRpc = await convertToExitAssets(BigInt(shares), pool);

      const exitAssets = buildValueInterfaceFromBigNumberValues(exitAssetsRpc, USDC_DECIMALS, STABLE_COINS_UI_DECIMALS);

      return {
        id: commitment.id,
        amount: amountFormatted,
        exitAssets,
        length,
        unlockDate,
        isExpired,
        unlockTimestamp,
        asset: pool.asset.symbol.toLowerCase() as PoolAssets,
      };
    }),
  );

  return items.filter(({ isExpired }) => !isExpired);
};

export const getFormattedStats = (
  poolV2s: PoolWithdrawalFragment[],
  account: GetPortfolioDataQuery['accountById'],
  positions: PoolV2PositionFragment[],
): StatsData => {
  const totalDrips = i18nStringifiedNumberFormat(account?.dripsEarned?.toString() ?? '0', 0);

  let totalInterestEarnedRaw = ZERO;
  let totalBalanceRaw = ZERO;
  let totalAvailableBalanceRaw = ZERO;

  for (const pool of poolV2s) {
    const asset = pool.asset.symbol.toLowerCase();
    const position = positions.find(p => p.pool.asset.symbol.toLowerCase() === asset);

    totalInterestEarnedRaw += BigInt(position?.interestEarned || '0');
    totalBalanceRaw += BigInt(position?.lendingBalance || '0');
    totalAvailableBalanceRaw += BigInt(position?.availableBalance || '0');
  }

  const totalInterestEarned = buildValueInterfaceFromBigNumberValues(
    totalInterestEarnedRaw > ZERO ? totalInterestEarnedRaw : ZERO,
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );
  const totalBalance = buildValueInterfaceFromBigNumberValues(totalBalanceRaw, USDC_DECIMALS, STABLE_COINS_UI_DECIMALS);
  const totalAvailableBalance = buildValueInterfaceFromBigNumberValues(
    totalAvailableBalanceRaw,
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );

  const usdtPosition = positions.find(({ pool }) => pool.asset.symbol.toLowerCase() === 'usdt');
  const usdcPosition = positions.find(({ pool }) => pool.asset.symbol.toLowerCase() === 'usdc');

  const usdtInterestEarned =
    BigInt(usdtPosition?.interestEarned || '0') > ZERO ? BigInt(usdtPosition?.interestEarned || '0') : ZERO;
  const usdcInterestEarned =
    BigInt(usdcPosition?.interestEarned || '0') > ZERO ? BigInt(usdcPosition?.interestEarned || '0') : ZERO;

  const usdtInterestEarnedFormatted = buildValueInterfaceFromBigNumberValues(
    usdtInterestEarned,
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );
  const usdcInterestEarnedFormatted = buildValueInterfaceFromBigNumberValues(
    usdcInterestEarned,
    USDC_DECIMALS,
    STABLE_COINS_UI_DECIMALS,
  );

  const targetYieldPositions = positions.map(position => ({
    balance: position.availableBalance || '0',
    assetDecimals: position.pool.asset.decimals,
    targetYield: position.pool.poolMeta?.targetYield || 0,
  }));
  const targetYieldAverage = getTargetYieldAverage(targetYieldPositions);

  return {
    totalDrips,
    interestEarned: {
      total: totalInterestEarned,
      usdc: usdcInterestEarnedFormatted,
      usdt: usdtInterestEarnedFormatted,
    },
    totalBalance,
    totalAvailableBalance,
    targetYieldAverage,
  };
};

export const getCommitments = async (
  poolV2: PoolWithdrawalFragment,
  commitmentData?: CommitmentFragment[],
): Promise<{ commitments: FormattedCommitment[]; firstCommitmentExpiry: CountdownResult | null }> => {
  let commitments: FormattedCommitment[] = [];
  let firstCommitmentExpiry: CountdownResult | null = null;

  if (commitmentData?.length) {
    commitments = await getFormattedCommitments(commitmentData, poolV2);

    if (commitments.length > 0) {
      const firstCommitment = commitments[0];
      firstCommitmentExpiry = getCountdownFromTimestamp(firstCommitment.unlockTimestamp);
    }
  }

  return { commitments, firstCommitmentExpiry };
};

export const getWithdrawalData = async (
  account: string,
  pool: PoolWithdrawalFragment,
  txes?: BasicTransactionFragment[] | null,
  currentPoolPosition?: PoolV2PositionFragment | null,
): Promise<WithdrawalData> => {
  const hasCurrentPosition = +currentPoolPosition?.totalShares > 0;
  if (!hasCurrentPosition) {
    return {
      ...defaultWithdrawalData,
      withdrawalState: 'NoPosition',
    };
  }

  let requestedWithdrawalTx;
  let requestedAmount = ZERO_USDC;
  let remainingAmount = ZERO_USDC;
  let withdrawalState: WithdrawalStates = 'Inactive';
  let withdrawalRequest: WithdrawalRequest | undefined;

  if (txes?.length) {
    requestedWithdrawalTx = txes
      .filter(({ symbol }) => symbol.toLowerCase() === pool.asset.symbol.toLowerCase())
      .find(({ type }) => type === TxType.PoolIntendToWithdraw);
  }

  const { withdrawalManagerQueue } = pool;
  if (withdrawalManagerQueue?.requests?.length) {
    const { requests } = withdrawalManagerQueue;

    const userRequestIndex = requests.findIndex(
      ({ owner }: WithdrawalRequest) => owner.toLowerCase() === account.toLowerCase(),
    );

    if (userRequestIndex !== -1) {
      withdrawalRequest = requests[userRequestIndex];

      const [requestAmountShares, remainingAmountShares] = await Promise.all([
        convertToExitAssets(BigInt(withdrawalRequest.initialShares), pool),
        convertToExitAssets(BigInt(withdrawalRequest.shares), pool),
      ]);

      requestedAmount = buildValueInterfaceFromBigNumberValues(
        requestAmountShares,
        USDC_DECIMALS,
        STABLE_COINS_UI_DECIMALS,
      );

      remainingAmount = buildValueInterfaceFromBigNumberValues(
        remainingAmountShares,
        USDC_DECIMALS,
        STABLE_COINS_UI_DECIMALS,
      );

      withdrawalState = requests[userRequestIndex].status as WithdrawalStates;
    }
  }

  return {
    requestedWithdrawalTx,
    requestedAmount,
    remainingAmount,
    withdrawalState,
  };
};
