import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Router
import { Router } from 'Router';

// Hooks
import useIsMarketingPage from 'Hooks/useIsMarketingPage';
import useRestricted from 'Hooks/useRestricted';
import useBreakpoint from 'Hooks/useBreakpoint';

// Components
import AppBarMarketing from 'Components/Navigation/AppBarMarketing';
import Sidebar from 'Components/Navigation/Sidebar';
import MobileBannersCarousel from 'Components/MobileBannersCarousel';
import AppBar from 'Components/Navigation/AppBar';
import FooterMarketing from 'Components/FooterMarketing';
import Footer from 'Components/Footer';
import MobileNav from './Navigation/MobileNav';
import CommitFlowModal from 'Pages/Portfolio/CommitFlowModal';

// Pages
import Error from 'Pages/Error/Error';

// Hooks
import useIsGnosisSafe from 'Hooks/useIsGnosisSafe';

const PageContainer = () => {
  const isMarketingPage = useIsMarketingPage();
  const { isRestricted } = useRestricted();
  const { isDesktopDevice, breakPoint } = useBreakpoint();
  const { pathname } = useLocation();
  const gnosisConnectionType = useIsGnosisSafe();

  const isGnosisSafe = useMemo(() => gnosisConnectionType !== 'none', [gnosisConnectionType]);
  const backgroundStyles = useMemo(() => {
    if (isRestricted)
      return {
        background: theme => ({ xs: theme.palette.gradient.circularAndLinear, md: 'none' }),
        backgroundSize: '200px 200px, cover, cover',
        backgroundPosition: 'top left, center, center',
        backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
      };
    return {};
  }, [isRestricted]);

  const pageContainerPadding = useMemo(() => {
    const paddingTopSm = { xs: 12, md: 16 };
    const paddingTopLg = { xs: 18, md: 20 };

    let pt = paddingTopLg;
    if (['/convert', '/stake'].includes(pathname)) {
      pt = paddingTopSm;
    }

    return {
      pt,
      pl: breakPoint === 'lg' ? '80px' : breakPoint === 'xxl' ? '216px' : 0,
    };
  }, [breakPoint, pathname]);

  return (
    <Box
      sx={{
        backgroundColor: isMarketingPage ? 'transparent' : 'background.weak',
        margin: '0 auto',
        overflow: 'hidden',
        width: '100vw',
        minHeight: '100vh',
      }}
    >
      <>
        {isMarketingPage ? (
          <AppBarMarketing />
        ) : (
          <>
            <AppBar />

            {isDesktopDevice ? (
              <Sidebar />
            ) : (
              <>
                <MobileNav />
                <MobileBannersCarousel />
              </>
            )}
          </>
        )}

        <Stack
          sx={
            isMarketingPage
              ? { pt: theme => theme.spacing(16) }
              : {
                  ...backgroundStyles,
                  ...pageContainerPadding,
                  mb: 4,
                  minHeight: theme => ({
                    xs: `calc(100vh - ${theme.spacing(26)})`,
                    md: `calc(100vh - ${theme.spacing(13)})`,
                  }),
                }
          }
        >
          <ErrorBoundary FallbackComponent={Error}>
            <Router />

            {/* Modals can be opened from Sidebar and Portfolio page */}
            {!isGnosisSafe && <CommitFlowModal />}
          </ErrorBoundary>
        </Stack>

        {isMarketingPage ? <FooterMarketing /> : <Footer />}
      </>
    </Box>
  );
};

export default PageContainer;
