import { FunctionComponent, SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  RemixiconComponentType,
  RiAddFill,
  RiAlertFill,
  RiArrowDownLine,
  RiArrowDownSFill,
  RiArrowDownSLine,
  RiArrowGoBackFill,
  RiArrowLeftLine,
  RiArrowRightDownLine,
  RiArrowRightLine,
  RiArrowRightSFill,
  RiArrowRightUpLine,
  RiArrowUpDownLine,
  RiArrowUpSLine,
  RiBarChartFill,
  RiCalendarFill,
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCloseFill,
  RiCloseLine,
  RiCoinsFill,
  RiDvdFill,
  RiErrorWarningLine,
  RiExchangeLine,
  RiFilePdfFill,
  RiFileTextFill,
  RiFundsFill,
  RiHandCoinFill,
  RiHandCoinLine,
  RiHandHeartFill,
  RiLockUnlockLine,
  RiLockUnlockFill,
  RiMenu5Line,
  RiMoreFill,
  RiPercentLine,
  RiPieChartFill,
  RiPlayCircleFill,
  RiPlayFill,
  RiPresentationFill,
  RiQuestionFill,
  RiSendPlaneFill,
  RiSpeakLine,
  RiStackLine,
  RiSubtractFill,
  RiSwapFill,
  RiTwitterXFill,
  RiUserStarLine,
  RiWallet2Line,
  RiTimeLine,
} from '@remixicon/react';

// Custom Icons
import { ReactComponent as IconBardFillGradient } from 'Assets/icon-bard-fill-gradient.svg';
import { ReactComponent as IconSyrupBottleLow } from 'Assets/icon-syrup-bottle-low.svg';
import { ReactComponent as IconSyrupBottleHalf } from 'Assets/icon-syrup-bottle-half.svg';
import { ReactComponent as IconSyrupBottleFull } from 'Assets/icon-syrup-bottle-full.svg';
import { ReactComponent as IconSyrupBottleContained } from 'Assets/icon-syrup-bottle-contained.svg';
import { ReactComponent as IconSyrupBottleMonochrome } from 'Assets/icon-syrup-bottle-monochrome.svg';
import { ReactComponent as IconSyrup } from 'Assets/icon-syrup.svg';
import { ReactComponent as IconSyrupInverted } from 'Assets/icon-syrup-inverted.svg';
import { ReactComponent as IconBullet } from 'Assets/icon-bullet.svg';
import { ReactComponent as IconTelegram } from 'Assets/icon-telegram.svg';
import { ReactComponent as IconGlobal } from 'Assets/icon-globe.svg';
import { ReactComponent as IconGas } from 'Assets/icon-gas.svg';
import { ReactComponent as IconEthereum } from 'Assets/icon-ethereum.svg';
import { ReactComponent as IconReferralLink } from 'Assets/icon-referral-link.svg';
import { ReactComponent as IconExodusWallet } from 'Assets/icon-exodus-wallet.svg';
import { ReactComponent as IconOkexWallet } from 'Assets/icon-okex-wallet.svg';
import { ReactComponent as IconBinanceWallet } from 'Assets/icon-binance-wallet.svg';
import { ReactComponent as IconEthCCLogo } from 'Assets/icon-ethcc-logo.svg';
import { ReactComponent as IconCampaignW3 } from 'Assets/icon-campaignW3-logo.svg';
import { ReactComponent as IconUniswap } from 'Assets/icon-uniswap.svg';
import { ReactComponent as IconCowswap } from 'Assets/icon-cowswap.svg';
import { ReactComponent as IconInsights } from 'Assets/icon-insights.svg';
import { ReactComponent as IconCommit } from 'Assets/icon-commit.svg';
import { ReactComponent as IconComplete } from 'Assets/icon-complete.svg';
import { ReactComponent as IconPending } from 'Assets/icon-pending.svg';
import { ReactComponent as IconCancelled } from 'Assets/icon-cancelled.svg';

// Asset Icons
import { ReactComponent as IconAAVE } from 'Assets/icon-aave.svg';
import { ReactComponent as IconADA } from 'Assets/icon-ada.svg';
import { ReactComponent as IconAKT } from 'Assets/icon-akt.svg';
import { ReactComponent as IconAUSDC } from 'Assets/icon-ausdc.svg';
import { ReactComponent as IconAUSDT } from 'Assets/icon-ausdt.svg';
import { ReactComponent as IconAVAX } from 'Assets/icon-avax.svg';
import { ReactComponent as IconBNB } from 'Assets/icon-bnb.svg';
import { ReactComponent as IconBTC } from 'Assets/icon-btc.svg';
import { ReactComponent as IconDAI } from 'Assets/icon-dai.svg';
import { ReactComponent as IconDripsDeposits } from 'Assets/icon-drips-deposits.svg';
import { ReactComponent as IconETH } from 'Assets/icon-eth.svg';
import { ReactComponent as IconFIL } from 'Assets/icon-fil.svg';
import { ReactComponent as IconFTM } from 'Assets/icon-ftm.svg';
import { ReactComponent as IconGBTC } from 'Assets/icon-gbtc.svg';
import { ReactComponent as IconINJ } from 'Assets/icon-inj.svg';
import { ReactComponent as IconLBTC } from 'Assets/icon-lbtc.svg';
import { ReactComponent as IconLTC } from 'Assets/icon-ltc.svg';
import { ReactComponent as IconLINK } from 'Assets/icon-link.svg';
import { ReactComponent as IconMPL } from 'Assets/icon-mpl.svg';
import { ReactComponent as IconORCALPPYUSDC } from 'Assets/icon-orca-lp-usdc.svg';
import { ReactComponent as IconPendle } from 'Assets/icon-pendle.svg';
import { ReactComponent as IconPendleInCircle } from 'Assets/icon-pendle-in-circle.svg';
import { ReactComponent as IconPOL } from 'Assets/icon-pol.svg';
import { ReactComponent as IconPTsUSDe } from 'Assets/icon-pt-susde.svg';
import { ReactComponent as IconSDAI } from 'Assets/icon-sdai.svg';
import { ReactComponent as IconSky } from 'Assets/icon-sky.svg';
import { ReactComponent as IconSOL } from 'Assets/icon-sol.svg';
import { ReactComponent as IconSonic } from 'Assets/icon-sonic.svg';
import { ReactComponent as IconSUSDS } from 'Assets/icon-susds.svg';
import { ReactComponent as IconUSDC } from 'Assets/icon-usdc.svg';
import { ReactComponent as IconUSDCUSDT } from 'Assets/icon-usdc-usdt.svg';
import { ReactComponent as IconUSDCPendle } from 'Assets/icon-usdc-pendle.svg';
import { ReactComponent as IconUSDT } from 'Assets/icon-usdt.svg';
import { ReactComponent as IconUSTB } from 'Assets/icon-ustb.svg';
import { ReactComponent as IconTETH } from 'Assets/icon-teth.svg';
import { ReactComponent as IconWBTC } from 'Assets/icon-wbtc.svg';
import { ReactComponent as IconWETH } from 'Assets/icon-weth.svg';
import { ReactComponent as IconWstETH } from 'Assets/icon-wstEth.svg';
import { ReactComponent as IconXMPL } from 'Assets/icon-xmpl.svg';
import { ReactComponent as IconXRP } from 'Assets/icon-xrp.svg';

type LibraryIcons =
  | 'add'
  | 'alertFill'
  | 'arrowDown'
  | 'arrowDownLine'
  | 'arrowDownSFill'
  | 'arrowDownSLine'
  | 'arrowGoBackFill'
  | 'arrowLeftLine'
  | 'arrowRight'
  | 'arrowRightDown'
  | 'arrowRightSFill'
  | 'arrowRightUp'
  | 'arrowUpDownLine'
  | 'arrowUpSLine'
  | 'barChartFill'
  | 'calendarFill'
  | 'checkCircle'
  | 'checkCircleFill'
  | 'closeFill'
  | 'closeLine'
  | 'coins'
  | 'coinsFill'
  | 'dvdFill'
  | 'errorWarningLine'
  | 'ellipsis'
  | 'exchangeLine'
  | 'filePdfFill'
  | 'fileTextFill'
  | 'fundsFill'
  | 'handCoinFill'
  | 'handCoinLine'
  | 'handHeartFill'
  | 'lockUnlockFill'
  | 'lockUnlockLine'
  | 'menuLine'
  | 'percentLine'
  | 'pieChartFill'
  | 'playCircleFill'
  | 'playFill'
  | 'presentationFill'
  | 'questionFill'
  | 'sendPlane'
  | 'speakLine'
  | 'stackLine'
  | 'subtract'
  | 'swapFill'
  | 'timeLine'
  | 'userStarLine'
  | 'wallet2Line'
  | 'xFill';

type CustomIcons =
  | 'bardFillGradient'
  | 'bulletPoint'
  | 'campaignW3'
  | 'cancelled'
  | 'commit'
  | 'complete'
  | 'cowswap'
  | 'dripsDeposits'
  | 'ethCC'
  | 'ethereum'
  | 'gas'
  | 'global'
  | 'insights'
  | 'pending'
  | 'referralLink'
  | 'syrup'
  | 'syrupBottleContained'
  | 'syrupBottleMonochrome'
  | 'syrupBottleFull'
  | 'syrupBottleHalf'
  | 'syrupBottleLow'
  | 'syrupInverted'
  | 'telegram'
  | 'uniswap'
  | 'walletBinance'
  | 'walletExodus'
  | 'walletOkex';

type AssetIcons =
  | 'aave'
  | 'ada'
  | 'akt'
  | 'ausdc'
  | 'ausdt'
  | 'avax'
  | 'bnb'
  | 'btc'
  | 'dai'
  | 'eth'
  | 'fil'
  | 'ftm'
  | 'gbtc'
  | 'inj'
  | 'lbtc'
  | 'ltc'
  | 'link'
  | 'mpl'
  | 'orcalp_pyusdc'
  | 'pendle'
  | 'pendleInCircle'
  | 'pol'
  | 'pt_susde'
  | 's'
  | 'sdai'
  | 'sky'
  | 'sol'
  | 'susds'
  | 'usdc-pendle'
  | 'usdc-usdt'
  | 'usdc'
  | 'usdt'
  | 'ustb'
  | 'teth'
  | 'wbtc'
  | 'weth'
  | 'wstEth'
  | 'xMPL'
  | 'xrp';

export type Icons = LibraryIcons | CustomIcons | AssetIcons;

type IconComponentType = RemixiconComponentType | FunctionComponent<SVGProps<SVGSVGElement>>;

const libraryIcons: Record<LibraryIcons, RemixiconComponentType> = {
  add: RiAddFill,
  alertFill: RiAlertFill,
  arrowDown: RiArrowDownLine,
  arrowDownLine: RiArrowDownLine,
  arrowDownSFill: RiArrowDownSFill,
  arrowDownSLine: RiArrowDownSLine,
  arrowGoBackFill: RiArrowGoBackFill,
  arrowLeftLine: RiArrowLeftLine,
  arrowRight: RiArrowRightLine,
  arrowRightDown: RiArrowRightDownLine,
  arrowRightSFill: RiArrowRightSFill,
  arrowRightUp: RiArrowRightUpLine,
  arrowUpDownLine: RiArrowUpDownLine,
  arrowUpSLine: RiArrowUpSLine,
  barChartFill: RiBarChartFill,
  calendarFill: RiCalendarFill,
  checkCircle: RiCheckboxCircleLine,
  checkCircleFill: RiCheckboxCircleFill,
  closeFill: RiCloseFill,
  closeLine: RiCloseLine,
  coins: RiCoinsFill,
  coinsFill: RiCoinsFill,
  dvdFill: RiDvdFill,
  errorWarningLine: RiErrorWarningLine,
  ellipsis: RiMoreFill,
  exchangeLine: RiExchangeLine,
  filePdfFill: RiFilePdfFill,
  fileTextFill: RiFileTextFill,
  fundsFill: RiFundsFill,
  handCoinFill: RiHandCoinFill,
  handCoinLine: RiHandCoinLine,
  handHeartFill: RiHandHeartFill,
  lockUnlockLine: RiLockUnlockLine,
  lockUnlockFill: RiLockUnlockFill,
  menuLine: RiMenu5Line,
  percentLine: RiPercentLine,
  pieChartFill: RiPieChartFill,
  playCircleFill: RiPlayCircleFill,
  playFill: RiPlayFill,
  presentationFill: RiPresentationFill,
  questionFill: RiQuestionFill,
  sendPlane: RiSendPlaneFill,
  speakLine: RiSpeakLine,
  stackLine: RiStackLine,
  subtract: RiSubtractFill,
  swapFill: RiSwapFill,
  timeLine: RiTimeLine,
  userStarLine: RiUserStarLine,
  wallet2Line: RiWallet2Line,
  xFill: RiTwitterXFill,
};

const customIcons: Record<CustomIcons, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  bardFillGradient: IconBardFillGradient,
  bulletPoint: IconBullet,
  campaignW3: IconCampaignW3,
  cancelled: IconCancelled,
  commit: IconCommit,
  complete: IconComplete,
  cowswap: IconCowswap,
  dripsDeposits: IconDripsDeposits,
  ethCC: IconEthCCLogo,
  ethereum: IconEthereum,
  gas: IconGas,
  global: IconGlobal,
  insights: IconInsights,
  pending: IconPending,
  referralLink: IconReferralLink,
  syrup: IconSyrup,
  syrupBottleContained: IconSyrupBottleContained,
  syrupBottleMonochrome: IconSyrupBottleMonochrome,
  syrupBottleFull: IconSyrupBottleFull,
  syrupBottleHalf: IconSyrupBottleHalf,
  syrupBottleLow: IconSyrupBottleLow,
  syrupInverted: IconSyrupInverted,
  telegram: IconTelegram,
  uniswap: IconUniswap,
  walletBinance: IconBinanceWallet,
  walletExodus: IconExodusWallet,
  walletOkex: IconOkexWallet,
};

const assetIcons: Record<AssetIcons, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  aave: IconAAVE,
  ada: IconADA,
  akt: IconAKT,
  ausdc: IconAUSDC,
  ausdt: IconAUSDT,
  avax: IconAVAX,
  bnb: IconBNB,
  btc: IconBTC,
  dai: IconDAI,
  eth: IconETH,
  fil: IconFIL,
  ftm: IconFTM,
  gbtc: IconGBTC,
  inj: IconINJ,
  lbtc: IconLBTC,
  ltc: IconLTC,
  link: IconLINK,
  mpl: IconMPL,
  orcalp_pyusdc: IconORCALPPYUSDC,
  pendle: IconPendle,
  pendleInCircle: IconPendleInCircle,
  pol: IconPOL,
  pt_susde: IconPTsUSDe,
  s: IconSonic,
  sky: IconSky,
  sdai: IconSDAI,
  sol: IconSOL,
  susds: IconSUSDS,
  'usdc-pendle': IconUSDCPendle,
  'usdc-usdt': IconUSDCUSDT,
  usdc: IconUSDC,
  usdt: IconUSDT,
  ustb: IconUSTB,
  teth: IconTETH,
  wbtc: IconWBTC,
  weth: IconWETH,
  wstEth: IconWstETH,
  xMPL: IconXMPL,
  xrp: IconXRP,
};

const iconMap: Record<Icons, IconComponentType> = {
  ...libraryIcons,
  ...customIcons,
  ...assetIcons,
};

export interface MapleIconProps extends SVGProps<SVGSVGElement> {
  icon: Icons;
  color?: string; // "primary.main" or {theme.palette.primary.main}
  size?: string | number;
}

const isValidColor = (color: string) => {
  const hexPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const rgbPattern = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
  const rgbaPattern = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(0|1|0\.\d+)\s*\)$/;

  return hexPattern.test(color) || rgbPattern.test(color) || rgbaPattern.test(color);
};

const MapleIcon = ({ icon, color = 'icon.strong', size = '24px', ...rest }: MapleIconProps) => {
  const theme = useTheme();
  const IconComponent = iconMap[icon];

  if (!IconComponent) {
    console.warn(`Icon '${icon}' not found in the iconMap.`);
    return null;
  }

  const getIconColor = () => {
    if (isValidColor(color)) return color;

    const [name, variant] = color.split('.');

    return theme.palette[name][variant];
  };

  const iconColor = getIconColor();

  const isRemixIcon = (icon: IconComponentType): icon is RemixiconComponentType => {
    // Check if the icon is of type RemixiconComponentType
    return typeof icon === 'function' && icon.name.startsWith('Ri');
  };

  const commonProps = {
    ...rest,
    color: iconColor,
    width: rest.width || size,
    height: rest.height || size,
  };

  if (isRemixIcon(IconComponent)) {
    // Cast rest to exclude children prop to satisfy RemixiconComponentType
    const { ...remixIconProps } = rest as Omit<SVGProps<SVGSVGElement>, 'children'>;
    return <IconComponent {...remixIconProps} color={iconColor} size={size} />;
  }

  return <IconComponent {...commonProps} fill={iconColor} />;
};

export default MapleIcon;
