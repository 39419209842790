import { Stack, useTheme } from '@mui/material';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';
import Banner from 'Components/Banner';
import MaplePopover from 'Components/MaplePopover';
import SidebarNavItem from 'Components/Navigation/SidebarNavItem';

// Constants
import { BANNERS } from 'Components/DesktopBannersCarousel';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';
import { useAnalytics } from 'Hooks/useAnalytics';

const UniswapBanner = ({ onClose }: { onClose: () => void }) => {
  const { analytics } = useAnalytics();
  const { isDesktopDevice, breakPoint } = useBreakpoint();
  const { palette } = useTheme();

  const handleClose = () => {
    analytics?.track('Banner - Uniswap - Close');
    localStorage.setItem(BANNERS.UNISWAP.storageKey, 'closed');
    onClose();
  };

  const handleOnclick = () => {
    analytics?.track('Banner - Uniswap - Navigate to Uniswap app');
    window.open('https://app.uniswap.org/explore/pools/ethereum/0xaB22d1D671Bb5CeE8735C5BA29eA651CcdA48A8E', '_blank');
  };

  if (breakPoint === 'lg')
    return (
      <MaplePopover
        copy={['You can now swap your syrupUSDC for any asset', 'via Uniswap instead of waiting for your withdrawal']}
      >
        <SidebarNavItem
          icon='uniswap'
          isActive={true}
          onClick={handleOnclick}
          sx={{
            '.badge__inner': {
              backgroundColor: palette.primary.light,
              svg: { fill: '#FC72FF', transform: 'translate(-1px, -1px)' },
            },
          }}
        />
      </MaplePopover>
    );

  return (
    <Banner
      media={
        <video width='100%' loop autoPlay muted playsInline>
          <source src='https://downloads.eth.maple.finance/videos/balancer_uniswap.mp4' type='video/mp4' />
        </video>
      }
      handleClose={handleClose}
      title='Instant withdrawals for syrupUSDC'
      description='You can now swap your syrupUSDC for any asset via Uniswap instead of waiting for your withdrawal'
      footer={
        <MapleButton
          fullWidth
          variant='contained'
          color='primary'
          size={isDesktopDevice ? 'xSmall' : 'medium'}
          startIcon={
            <Stack
              alignItems='center'
              justifyContent='center'
              sx={{
                backgroundColor: theme => theme.palette.background.paper,
                borderRadius: theme => theme.shape.round,
                width: '20px',
                height: '20px',
                svg: {
                  transform: 'translate(-1px, -1px)',
                },
              }}
            >
              <MapleIcon icon='uniswap' color='#FC72FF' size='2px' />
            </Stack>
          }
          onClick={handleOnclick}
        >
          Swap now
        </MapleButton>
      }
    />
  );
};

export default UniswapBanner;
