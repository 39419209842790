import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { PoolAssets } from 'Context/Portfolio/Portfolio';
import { defaultCancelWithdrawalFormValues } from 'Utils/defaultValues';
import {
  baseTransactionControls,
  defaultResetControls,
  ResetControls,
  TransactionConfig,
  TransactionControls,
} from 'Utils/transactionConfig';

type CancelWithdrawalSteps = 'cancel' | 'success' | 'fail';
type CancelWithdrawalTxType = 'cancel';

export interface CancelWithdrawalFormValues {
  asset: PoolAssets;
  shares: bigint;
  assets: bigint;
}

type CancelWithdrawalData = TransactionConfig<CancelWithdrawalSteps, CancelWithdrawalTxType> &
  TransactionControls<CancelWithdrawalSteps> &
  ResetControls & {
    formValues: CancelWithdrawalFormValues;
    txHash: string;
    setTxHash: Dispatch<SetStateAction<string>>;
    setFormValues: Dispatch<SetStateAction<CancelWithdrawalFormValues>>;
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    openCancelModal: (formValues: CancelWithdrawalFormValues) => void;
  };

const defaultTransactionConfig: TransactionConfig<CancelWithdrawalSteps, CancelWithdrawalTxType> = {
  flowType: 'normal',
  txType: 'cancel',
  steps: ['cancel'],
  setSteps: () => {},
};

const defaultTransactionControls: TransactionControls<CancelWithdrawalSteps> = {
  ...baseTransactionControls,
  step: 'cancel',
};

const defaultCancelWithdrawalContext: CancelWithdrawalData = {
  ...defaultTransactionConfig,
  ...defaultTransactionControls,
  ...defaultResetControls,
  formValues: { ...defaultCancelWithdrawalFormValues },
  setFormValues: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  openCancelModal: () => {},
  txHash: '',
  setTxHash: () => {},
};

export const CancelWithdrawalContext = createContext<CancelWithdrawalData>(defaultCancelWithdrawalContext);

export interface CancelWithdrawalContextProps {
  children: ReactNode;
}

export const CancelWithdrawalProvider = ({ children }: CancelWithdrawalContextProps) => {
  const [txHash, setTxHash] = useState('');
  const [step, setStep] = useState<CancelWithdrawalSteps>('cancel');
  const [steps, setSteps] = useState<CancelWithdrawalSteps[]>(['cancel']);
  const [isDoingTx, setIsDoingTx] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<CancelWithdrawalFormValues>({ ...defaultCancelWithdrawalFormValues });

  const init = () => {
    setStep('cancel');
  };

  const openCancelModal = (formValues: CancelWithdrawalFormValues) => {
    setIsModalOpen(true);
    setFormValues({ ...formValues });
  };

  // 🚨 --- Resets
  // ------------------------------------------------------------
  // softReset():
  // - What? -  Resets "Transaction State"
  const resetTxState = () => {
    setStep('cancel');
    setIsDoingTx(false);
  };

  // hardReset():
  // - What? -  Resets "Transaction Config" and "Transaction State"
  const resetTxConfig = () => {
    setStep('cancel');
    setIsModalOpen(false);
  };

  const softReset = () => {
    resetTxState();
  };

  const hardReset = () => {
    resetTxConfig();
    resetTxState();
  };

  const reinitialize = () => {
    hardReset();
    setTxHash('');
    setFormValues({ ...defaultCancelWithdrawalFormValues });
    init();
  };

  return (
    <CancelWithdrawalContext.Provider
      value={{
        // ⚙️ Transaction Config ⚙️
        flowType: 'normal',
        txType: 'cancel',
        steps,
        setSteps,

        // 🛠️ Transaction State 🛠️
        step,
        setStep,
        isDoingTx,
        setIsDoingTx,
        isModalOpen,
        setIsModalOpen,

        // ↩️ Resets ↩️
        softReset,
        hardReset,
        reinitialize,

        // 📖 Form Values 📖
        formValues,
        setFormValues,

        // Other values
        openCancelModal,
        txHash,
        setTxHash,
      }}
    >
      {children}
    </CancelWithdrawalContext.Provider>
  );
};
