import { ReactNode } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleIconBadge from 'Components/ds/MapleIconBadge';
import MapleTooltip, { MapleTooltipProps } from 'Components/ds/MapleTooltip';
import MapleButton from 'Components/ds/MapleButton';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Utils
import { PoolAssets } from 'Context/Portfolio/Portfolio';
import MaplePill, { MaplePillProps } from 'Components/ds/MaplePill';

const styles = {
  width: '100%',
  padding: theme => `${theme.spacing(2, 0)} !important`,
  '&:nth-last-of-type(1)': {
    padding: 0,
  },
};

export interface CommonProps {
  title: string;
  asset: PoolAssets | 'usdc-pendle';
  copy?: string;
  badge?: React.ReactNode;
  pill?: MaplePillProps;
  id: string;
}

interface BasicItem extends CommonProps {
  type: 'basic';
}

interface TextItem extends CommonProps {
  type: 'text';
  value: string;
  tooltip?: MapleTooltipProps;
}

interface CTAItem extends CommonProps {
  type: 'cta';
  items: Array<ReactNode> | [];
}

export interface LinkItem extends CommonProps {
  type: 'link';
  url: string;
  value: string;
  amount: ReactNode;
  tooltip?: MapleTooltipProps;
  cta?: ReactNode;
}

export type PortfolioRowItemProps = BasicItem | TextItem | CTAItem | LinkItem;

const PortfolioRowItem = ({ ...props }: PortfolioRowItemProps) => {
  const { copy, title, badge, type, asset, id, pill } = props;

  const { isDesktopDevice } = useBreakpoint();

  const iconDimension = isDesktopDevice ? '40px' : '30px';

  const textProps = {
    variant: 'paragraph' as const,
    size: 'medium' as const,
    color: 'text.strong',
  };
  let cellValue: ReactNode;

  switch (type) {
    case 'text': {
      const { tooltip, value } = props;

      cellValue = (
        <Stack>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={0.5}>
            <MapleTypography {...textProps} textAlign='right'>
              {value}
            </MapleTypography>
            {tooltip && <MapleTooltip {...tooltip} />}
          </Stack>
          <MapleTypography
            {...textProps}
            sx={{ display: { xs: 'none', md: 'block' }, opacity: 0, cursor: 'none', userSelect: 'none' }}
          >
            {value}
            {/* ghost row */}
          </MapleTypography>
        </Stack>
      );
      break;
    }
    case 'cta':
      cellValue = (
        <Stack direction='row' spacing={1} sx={{ width: { xs: '100%', md: 'auto' } }}>
          {props.items.map((item, index) => (
            <Box key={index} sx={{ width: { xs: '100%', md: 'auto' } }}>
              {item}
            </Box>
          ))}
        </Stack>
      );
      break;
    case 'link': {
      const { amount, url, value, tooltip } = props;
      cellValue = (
        <Stack sx={{ a: { textDecoration: 'none' } }}>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={0.5}>
            <MapleTypography {...textProps}>{amount}</MapleTypography>
            {tooltip && <MapleTooltip {...tooltip} />}
          </Stack>

          <Link href={url} target='_blank' rel='noopener'>
            <MapleButton variant='textLink' endIcon={<MapleIcon icon='arrowRightUp' />} size='small'>
              {value}
            </MapleButton>
          </Link>
        </Stack>
      );
      break;
    }
  }

  return (
    <Box sx={{ ...styles }} key={`portfolio-row-item-${id}`}>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={2} justifyContent='space-between'>
          <Stack direction='row' justifyContent='flex-start' spacing={2}>
            <MapleIconBadge icon={asset} width={iconDimension} height={iconDimension} badge={badge} />
            <Stack direction='column' textAlign='left' justifyContent={copy ? 'space-between' : 'center'}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <MapleTypography variant='paragraph' size='medium'>
                  {title}
                </MapleTypography>
                {pill && isDesktopDevice && <MaplePill {...pill} />}
              </Stack>

              {copy && (
                <MapleTypography variant='paragraph' size='small' color='text.soft'>
                  {copy}
                </MapleTypography>
              )}
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2}>
            {cellValue}
            {type === 'link' && isDesktopDevice && props.cta}
          </Stack>
        </Stack>
        {type === 'link' && !isDesktopDevice && props.cta && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>{props.cta}</Box>
        )}
      </Stack>
    </Box>
  );
};

export default PortfolioRowItem;
